import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import antd from '@/plugins/antdImport.js'
import vueSvgInlinePlugin from '@/plugins/vueSvgInlinePluginImport.js'
import pinia from '@/plugins/piniaImport.js'

const app = createApp(App)

app
  .use(antd)
  .use(pinia)
  .use(vueSvgInlinePlugin)
  .use(router)
  .mount('#app')
