<template>
  <a-config-provider :locale="locale">
    <router-view v-slot="{ Component }" :key="$route.meta.name">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.meta.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.meta.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
  </a-config-provider>
</template>
<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

const locale = zhCN;
</script>
<style lang="less">
html,
body,
#app {
  margin: 0;
  height: 100%;
  font-family: PingFang SC;
  // ::-webkit-scrollbar {
  //   width: 0;
  //   height: 0;
  // }
}
:deep(.ant-message-notice-content) {
    margin-top: 50px;
}
</style>
